<template>
  <div class="score_page">
    <img
      style="width:100%;margin-top:20px"
      src="../../assets/img/home/ranking/banner.png"
      alt=""
    />
    <div v-if="!isAll">
      <scoreList
        title="幼儿园积分展示"
        :type="constant.integralType.integral"
        :list="list1"
        @handleMore="handleMoreOne"
        time="2020.09.01—2021.07.31"
      />
      <scoreList
        title="幼儿园活跃积分展示"
        :type="constant.integralType.activeIntegral"
        :list="list2"
        @handleMore="handleMoreTwo"
        time="2021.07.01—2021.07.31"
      />
    </div>
    <div>
      <scoreList
        v-if="isAll"
        :title="allScoreTitle"
        @selectChange="selectChange"
        :type="isAllType"
        :list="list3"
        :isShowMore="false"
        time="2020.09.01—2021.07.31"
      />
    </div>
  </div>
</template>

<script>
import scoreList from '@/components/home/scoreList.vue'
export default {
  name: 'ScorePage',
  components: {
    scoreList,
  },
  data() {
    return {
      columns: {},
      isAll: false, // 是否显示全部数据
      isAllType: '',
      list1: [],
      list2: [],
      list3: [],

      // 全部数据
      allScoreTitle: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 初始化函数
    init() {
      this.getIntegralShow1('', this.constant.integralType.integral, 10)
      this.getIntegralShow2('', this.constant.integralType.activeIntegral, 10)
    },
    // 积分接口调用
    getIntegralShow1(province, type, limit) {
      let data = {
        province,
        type,
        limit,
      }
      this.api.home.integralShow(data).then((res) => {
        let result = [...res.data]
        this.list1 = result
      })
    },
    getIntegralShow2(province, type, limit) {
      let data = {
        province,
        type,
        limit,
      }
      this.api.home.integralShow(data).then((res) => {
        let result = [...res.data]
        this.list2 = result
      })
    },
    getIntegralShow3(province, type, limit) {
      let data = {
        province,
        type,
        limit,
      }
      this.api.home.integralShow(data).then((res) => {
        let result = [...res.data]
        this.list3 = result
      })
    },
    //积分展示 点击更多
    handleMoreOne() {
      this.allScoreTitle = '幼儿园积分展示'
      this.isAll = true
      this.isAllType = this.constant.integralType.integral
      this.getIntegralShow3('', this.constant.integralType.integral, 50)
    },
    //活跃积分展示 点击更多
    handleMoreTwo() {
      this.allScoreTitle = '幼儿园活跃积分展示'
      this.isAll = true
      this.isAllType = this.constant.integralType.activeIntegral
      this.getIntegralShow3('', this.constant.integralType.activeIntegral, 50)
    },
    selectChange(val, type) {
      // console.log(val,type);
      this.list3 = this.getIntegralShow3(val, type, 50)
    },
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if (!this.isAll) {
      next()
    } else {
      this.isAll = false
      this.isAllType = ''
      next(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.score_page {
  width: 80%;
  margin: 0 auto;
}
</style>
