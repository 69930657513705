<template>
  <div class="score_list_block">
    <div class="title">
      {{ title }}
      <div class="time" v-if="title==='幼儿园积分展示'">2020.09.01—2021.07.31</div>
      <div class="time" v-else>2021.07.01—2021.07.31</div>
    </div>
    <el-select
      @change="selectChange"
      class="select_block"
      popper-class="select_option_block"
      v-show="!isShowMore"
      v-model="selectValue"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.name"
        :label="item.name"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-table
      header-align="center"
      stripe
      header-cell-class-name="header_row_style2"
      :data="list"
      style="width: 100%"
    >
      <el-table-column
        label="序号"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div class="table_flex">
            <i
              v-if="scope.$index < 3"
              class="score_icon"
              :class="{
                first: scope.$index === 0,
                secound: scope.$index === 1,
                third: scope.$index === 2,
              }"
            ></i>
            <span>{{ scope.$index + 1 }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        align="center"
        v-for="item in columns"
        :key="item.prop"
        :prop="item.prop"
        :width="item.width"
        :label="item.label"
      >
      </el-table-column>
    </el-table>
    <div class="bottom">
      <span>本积分展示为定期更新</span>
      <div>
        <span class="tab" v-if="isShowMore" @click="handleMore(type)"
          >查看更多 >></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoreListBlock',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Array,
      default: () => {
        return [
          {
            label: '幼儿园名称',
            prop: 'schoolName',
          },
          {
            label: '所属省市',
            prop: 'province',
          },
          {
            label: '活跃积分',
            prop: 'integral',
          },
        ]
      },
    },
    isShowMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectValue: '',
      options: [],

      pageType: 0,
    }
  },
  async created() {
    let res = await this.api.home.findByPid()
    res.data.forEach((item) => {
      item.value = item.name
    })
    this.options = [{ name: '全部', value: '' }, ...res.data]
  },
  mounted() {},
  methods: {
    handleMore(type) {
      this.$emit('handleMore', type)
    },
    selectChange(val) {
      console.log(val);
      this.$emit('selectChange', val, this.type)
    },
  },
}
</script>

<style lang="scss" scoped>
.score_list_block {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
  .title {
    height: 74px;
    background-color: #cb1b31;
    font-size: 34px;
    line-height: 74px;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
    position: relative;
  }
  .time {
    position: absolute;
    right: 30px;
    top: 0;
    font-size: 20px;
    font-weight: normal;
  }
  .bottom {
    @include flex(row, space-between, center);
    font-size: 24px;
    color: #cb1b31;
    line-height: 40px;
  }
  .select_block {
    width: 300px;
    @include flex(row, flex-start, center);
    margin: 10px 0;
  }
}
</style>
<style lang="scss">
.score_list_block {
  .header_row_style2 {
    border-bottom: solid 4px #cb1b31 !important;
    border-top: solid 4px #cb1b31;
    color: #1a1a1a;
    height: 42px;
    font-weight: 700;
  }
  .table_flex {
    position: relative;
    @include flex();
  }
  .score_icon {
    position: absolute;
    left: 10px;
  }
  .first {
    @include bg('../../assets/img/home/ranking/cup.png');
    width: 20px;
    height: 20px;
  }
  .secound {
    @include bg('../../assets/img/home/ranking/cup2.png');
    width: 20px;
    height: 20px;
  }
  .third {
    @include bg('../../assets/img/home/ranking/cup3.png');
    width: 20px;
    height: 20px;
  }
  .el-table td {
    border: none;
  }
  .el-table tr:last-child td {
    border-bottom: solid 8px #cb1b31 !important;
  }
}
.select_option_block li {
  padding-left: 10px !important;
}
</style>
